<template>
    <v-dialog
            v-model="dialog"
            max-width="380"
    >

        <v-card>
            <v-card-title
                class="headline red lighten-2"
                primary-title
            >
                <span class="white--text">Acciones</span>
            </v-card-title>

            <v-card-text class="pt-3">
                <h3>Cambios al internamiento de:</h3>
                <span>({{internamiento.cip}}) {{internamiento.nombreCompleto}}</span><br/>
                <span class="font-weight-black">CAMA:</span> {{internamiento.cama}}
                <!-- El selectt para el tipo de cambio -->
                <v-select
                    class="mt-3"
                    v-model="accion"
                    :items="itemsTipo"
                    label="Movimiento"
                    filled
                    rounded
                    dense
                ></v-select>

                <v-expand-transition>
                    <!-- Cambio de AREA -->
                    <div v-if="accion==1">
                        <span class="font-weight-black">ÁREA FÍSICA en donde se ubicará el paciente</span>
                        <v-select
                            class="mt-3"
                            v-model="nuevaArea"
                            :items="$store.state.catalogos.areas"
                            label="Área"
                            item-text="area"
                            item-value="id"
                            filled
                            rounded
                            dense
                            @focus="$store.dispatch('catalogos/cargaArea')"
                        ></v-select>
                        

                    </div>


                    <!-- Cambio de CAMA -->
                    <div v-if="loadingCambiaCama">
                        <v-skeleton-loader
                            type="list-item-two-line, list-item-two-line"
                        ></v-skeleton-loader>
                    </div>
                    <div v-if="accion==2 && !loadingCambiaCama">
                        <span class="font-weight-black">Camas disponibles</span>
                        <v-list dense rounded flat nav>
                            <v-list-item
                                v-for="(item, index) in camaLibre"
                                :key="index"
                                dense
                                class="my-1 grey lighten-3"
                            >
                                <v-list-item-title>{{ item.cama }}</v-list-item-title>
                                <v-list-item-action><v-icon @click="actualizaCama(item.cama)"> mdi-check-bold</v-icon></v-list-item-action>
                            </v-list-item>
                        </v-list>

                    </div>


                    <!-- Cambio de SERVICIO -->
                    <div v-if="accion==3">
                        <span class="font-weight-black">SERVICIO al que pertenece el paciente</span><br/>
                        <span class="font-weight-black">Servicio actual: </span>{{internamiento.servicioNombre}}
                        <v-select
                            class="mt-3"
                            v-model="nuevoServicio"
                            :items="$store.state.catalogos.servicios"
                            label="Servicio"
                            item-text="servicio"
                            item-value="id"
                            filled
                            rounded
                            dense
                            @focus="$store.dispatch('catalogos/cargaServicio')"
                        ></v-select>
                    </div>


                    <!-- EGRESO -->
                    <div v-if="accion==4">

                        <v-alert type="error" text border="left" v-if="notaEgresoAlert">
                            No existe nota de egreso
                        </v-alert>

                        <v-alert type="error" text border="left" v-if="padeAlert">
                            No hay padecimientos marcados como defuncion
                        </v-alert>


                        <v-form v-model="egresoValido" ref="formAlta">
                            <v-select
                                class="mt-3"
                                v-model="datosEgreso.tipoAlta"
                                :items="$store.state.catalogos.tipoAlta"
                                label="Tipo de egreso"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"
                                item-text="tipoAlta"
                                item-value="id"
                                filled
                                rounded
                                dense
                                @focus="$store.dispatch('catalogos/tipoAlta')"
                            ></v-select>

                            <!-- El picker para la fecha de egreso -->
                            <v-menu
                                v-model="menuFecha"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="datosEgreso.fechaAlta"
                                        label="Fecha de egreso"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        filled
                                        rounded
                                        dense
                                        :rules="[
                                            value => !!value || 'Requerido.',
                                        ]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="datePicker"
                                    @input="menuFecha = false"

                                    no-title
                                    scrollable
                                    locale="es-mx"
                                    show-current
                                />
                            </v-menu>

                            <v-text-field
                                v-model="datosEgreso.horaAlta"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                    value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                                ]"
                                filled
                                rounded
                                dense
                                label="Hora de egreso (HH:MM 24 hrs)"
                            ></v-text-field>

                            <v-textarea
                                v-model="datosEgreso.observacionesAlta"
                                filled
                                rounded
                                dense
                                label="Observaciones de egreso"
                            ></v-textarea>


                        </v-form>
                        
                    </div>
                </v-expand-transition>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <!-- Para el cambio de AREA -->
                <v-btn
                    v-if="accion==1"
                    :disabled="!Boolean(nuevaArea)"
                    :loading="loadingCambiaArea"
                    color="success"
                    @click="cambiaArea"
                >
                    Cambiar de área
                </v-btn>

                <!-- Para el cambio de SERVICIO -->
                <v-btn
                    v-if="accion==3"
                    :disabled="!Boolean(nuevoServicio)"
                    :loading="loadingCambiaServicio"
                    color="success"
                    @click="cambiaServicio"
                >
                    Cambiar de servicio
                </v-btn>

                <!-- Para el EGRESO -->
                <v-btn
                    v-if="accion==4"
                    :disabled="!Boolean(validaEgreso)"
                    :loading="loadingEgreso"
                    color="success"
                    @click="altaInternamiento"
                >
                    Egresar
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    // Busca nota de egreso
    // en /hospital/totalNotaEncuentro, internamiento y tipoNota = 11 (egreso)

    props:{
        dialogCambio:Boolean,
        internamiento:Object,
        camaLibre:Array,
    },

    data:()=>({
        accion:null,
        itemsTipo:[
            {value:1, text:'Cambio de ÁREA'},
            {value:2, text:'Cambio de CAMA'},
            {value:3, text:'Cambio de SERVICIO'},
            {value:4, text:'Egreso'},
        ],

        // Para áreas
        nuevaArea:null, // <--------------------
        loadingCambiaArea:false,

        // Para el cambio de camas
        loadingCambiaCama:false,

        // Para el cambio de servicio
        nuevoServicio:null,
        loadingCambiaServicio:false,

        // Para el egreso
        datosEgreso:{
            fechaAlta:null,
            tipoAlta:null,
            horaAlta:null,
            observacionesAlta:null,
        },

        egresoValido:false,

        tieneNotaEgreso:false,
        notaEgresoAlert:false,

        tienePadeAlta:false,
        padeAlert:false,
        loadingEgreso:false,
        //tipoEgreso:null,
        
        menuFecha:false,
        datePicker:'',

    }),

    methods:{
        async actualizaCama(cama){
            this.loadingCambiaCama = true
            let params = {
                movimiento:this.internamiento.movimiento,
                internamiento:this.internamiento.internamiento,
                area: this.internamiento.area,
                servicio:this.internamiento.servicio,
                cama:cama,
            }
            try{
                let aca = await this.$http({
                    method: 'POST',
                    url:'/movimientos/actualizacama',
                    params:params
                })

                this.loadingCambiaCama = false

                if (aca.data.estado == 'true'){
                    this.dialog=false
                    this.$emit('asignado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.loadingCambiaCama = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async altaInternamiento(){
            this.loadingEgreso = true
            let params = {

                ...this.datosEgreso,

                tipoMovimiento:4,

                movimiento:this.internamiento.movimiento,
                internamiento:this.internamiento.internamiento,
                area: this.internamiento.area,
                servicio:this.internamiento.servicio,
                cama:this.internamiento.cama,
            }

           try{
                let egr = await this.$http({
                    method:'POST',
                    url:'/movimientos/altainternamiento',
                    params:params
                })

                this.loadingEgreso=false

                if (egr.data.success == 'true'){
                    this.dialog=false
                    this.$emit('asignado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingEgreso = false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        async buscaPadeDefuncion(){
            
            try{
                let neg = await this.$http({
                    url:'/hospital/listaPadecimientosDefuncion',
                    method:'POST',
                    params:{
                        internamiento:this.internamiento.internamiento
                    }
                })
                if (neg.data.total >0){
                    this.tienePadeAlta=true
                    this.padeAlert=false
                } else {
                    this.padeAlert = true
                }
            } catch(err){
                
                this.$store.dispatch('security/revisaError',err)
            }

        },
        async buscaNotaEgreso(){
            
            try{
                let neg = await this.$http({
                    url:'/hospital/totalNotaEncuentro',
                    method:'POST',
                    params:{
                        tipoNota:11,
                        internamiento:this.internamiento.internamiento
                    }
                })
                if (neg.data.total >0){
                    this.tieneNotaEgreso=true
                    this.notaEgresoAlert=false
                } else {
                    this.notaEgresoAlert = true
                }
            } catch(err){
                
                this.$store.dispatch('security/revisaError',err)
            }

        },
        async cambiaArea(){
            this.loadingCambiaArea = true
            let params = {
                movimiento:this.internamiento.movimiento,
                internamiento:this.internamiento.internamiento,
                area: this.nuevaArea,
                servicio:this.internamiento.servicio
            }
            try{
                let car = await this.$http({
                    method: 'POST',
                    url:'/movimientos/cambioarea',
                    params:params
                })

                this.loadingCambiaArea = false

                if (car.data.estado == 'true'){
                    this.dialog=false
                    this.$emit('asignado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.loadingCambiaArea = false
                this.$store.dispatch('security/revisaError',err)
            }


        },
        async cambiaServicio(){
            this.loadingCambiaServicio = true
            let params = {
                movimiento:this.internamiento.movimiento,
                internamiento:this.internamiento.internamiento,
                area: this.internamiento.area,
                servicio:this.internamiento.servicio,
                servicioNuevo:this.nuevoServicio,
                cama:this.internamiento.cama,
                tipoMovimiento: 3
            }
            try{
                let cse = await this.$http({
                    method: 'POST',
                    url:'/movimientos/cambioservicio',
                    params:params
                })

                this.loadingCambiaServicio = false

                if (cse.data.success == 'true'){
                    this.dialog=false

                    this.$swal.fire({
                        icon: 'success',
                        title: 'Cambiado',
                        text: 'Servicio cambiado exitosamente',
                    })

                    this.$emit('asignado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.loadingCambiaServicio = false
                this.$store.dispatch('security/revisaError',err)
            }


        },

    },

    computed:{
        dialog:{
            get(){
                return this.dialogCambio
            },
            set(val){
                // Limpia los datos
                this.accion = null
                this.nuevaArea = null
                this.nuevoServicio = null
                
                this.tipoEgreso=null
                this.notaEgresoAlert = false

                this.tienePadeAlta=null
                this.padeAlert=false


                this.datosEgreso={
                    fechaAlta:null,
                    tipoAlta:null,
                    horaAlta:null,
                    observacionesAlta:null,
                }

                // Cierra el dialog
                this.$emit('update:dialogCambio',val)
            },
        },
        validaEgreso(){
            return (this.egresoValido && !this.notaEgresoAlert && !this.padeAlert)
        }
    },

    watch:{
        accion(){
            if (this.accion == 4){
                this.buscaNotaEgreso()
                //console.log('Seleccionando egreso')
                
                
                this.tieneNotaEgreso=true
                this.notaEgresoAlert=false
                
                this.datosEgreso.fechaAlta = this.moment(new Date()).format('DD/MM/YYYY')
                this.datosEgreso.horaAlta = this.moment(new Date()).format('HH:mm')
            }
        },
        datePicker(val){
            if (val){
                let f1 = val.split('-')
                //console.log(f1)
                this.datosEgreso.fechaAlta = f1[2]+'/'+f1[1]+'/'+f1[0] 
            }
        },
        
        'datosEgreso.tipoAlta'(){
            
            this.tieneNotaEgreso=true
            this.notaEgresoAlert=false

            this.tienePadeAlta=true
            this.padeAlert=false

            if(this.datosEgreso.tipoAlta !=5){
                this.buscaNotaEgreso()
            }

            if (this.datosEgreso.tipoAlta ==4){
                this.buscaPadeDefuncion()
            }
        }
        
    },

}
</script>

<style>

</style>