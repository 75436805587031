<template>
    <v-dialog
        v-model="dialog"
        max-width="1000"
       
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{moment(estaFechaDisplay).format('dddd MMMM D, YYYY')}}

                    <v-spacer/>

                    <v-btn text icon @click="diaAnterior" :disabled="loading">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-chevron-left
                                </v-icon>
                            </template>
                            <span>Día anterior</span>
                        </v-tooltip>
                    </v-btn>

                    <v-menu
                        ref="menuFecha"
                        v-model="menuFecha"
                        :close-on-content-click="false"
                        
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                            >
                                <v-icon>mdi-calendar-month</v-icon>
                            </v-btn>
                            
                        </template>
                            <v-date-picker
                                v-model="pickerFecha"
                                no-title
                                reactive
                                show-current
                                :max="estaFechaGral"
                                locale="es-MX"
                                
                                @change="seleccionaFecha"
                            >
                            <v-spacer></v-spacer>

                            <v-btn text color="primary" @click="pickerFecha = new Date().toISOString().substring(0,10)">Hoy</v-btn>

                        </v-date-picker>
                    </v-menu>
                    

                    <v-btn text icon @click="diaSiguiente" :disabled="loading">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-chevron-right
                                </v-icon>
                            </template>
                            <span>Día siguiente</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn text icon @click="imprime" :disabled="loading">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-printer
                                </v-icon>
                            </template>
                            <span>Imprime</span>
                        </v-tooltip>
                    </v-btn>

                </v-card-title>

                <v-card-text class="pt-5">

                    <div v-if="loading">
                        <v-skeleton-loader
                            
                            type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                            
                        ></v-skeleton-loader>
                    </div>

                    <v-alert
                        v-if="!loading && movimientos.length == 0"
                        dense
                        text
                        border="left"
                        type="info"
                    >
                        No hay movimientos para este día
                    </v-alert>

                    <div v-if="!loading">
                         <v-simple-table dense>
                            <template v-slot:default>

                                <thead>
                                    <tr>
                                        <th class="green lighten-3 text-center"><span class="body-2 font-weight-black">Cama</span></th>
                                        <th class="green lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                                        <th class="green lighten-3 text-center"><span class="body-2 font-weight-black">Paciente</span></th>
                                        <th class="green lighten-3 text-center"><span class="body-2 font-weight-black">Motivo</span></th>
                                        <th class="green lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-3" v-for="item in movimientos" :key="item.id">
                                        <td width="10%" class="caption">
                                            {{item.cama}}<br/>
                                            <v-btn small icon @click="cargaInternamiento(item.internamiento)">
                                                <v-icon small>
                                                    mdi-magnify
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                        <td width="8%" centered>
                                            <div v-if="item.ingreso == 1" class="text-center">
                                                <v-icon small>mdi-login</v-icon><br/>
                                                <span class="overline">{{item.dateCreated}}</span>
                                            </div>
                                            <div v-if="item.ingreso !==1 && item.ingreso !== 0" class="text-center">
                                                <span class="overline">{{item.ingreso}}</span><br/>
                                                <span class="overline">{{item.dateCreated}}</span>
                                                <v-icon small>mdi-arrow-collapse-right</v-icon>

                                            </div>


                                        </td>
                                        <td width="35%" >
                                            <span class = "overline">({{item.paciente}}) {{item.pacienteNombre}}<br/></span>
                                            <span class="overline">del servicio de {{item.servicioNombre}}</span>
                                        </td>
                                        <td width="30%" class="overline">
                                            {{item.motivo}}
                                        </td>


                                        <td width="5%" class="overline">

                                            <div v-if="item.aunInternado == -1 && item.movimientoTipo == 1" class="text-center">
                                                <v-icon small>mdi-sync</v-icon><br/>
                                                {{item.dateEnd}}
                                            </div>

                                            <div v-if="item.aunInternado == -1 && item.movimientoTipo == 3" class="text-center">
                                                <v-icon small>mdi-arrow-collapse-right</v-icon> {{item.dateEnd}}<br/>
                                                {{item.servicioDestino[0]}}<br/>
                                                
                                            </div>

                                            <div v-if="item.aunInternado == -1 && item.movimientoTipo == 4" class="text-center">
                                                <v-icon small>mdi-logout</v-icon><br/>
                                                {{item.dateEnd}}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </template>
                         </v-simple-table>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" @click="dialog=false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
        </v-card>
        <imprime-censo
            :dialogImprime.sync="dialogImprime"
            :datos='movimientos'
            :totales="totales"
            :estaFecha='moment(estaFechaDisplay).format("dddd MMMM D, YYYY")'
            :areaText="areaText"
            :totalesProc="totalesProc"
        />
    </v-dialog>
</template>

<script>
export default {
    components:{
        'imprime-censo':()=> import('@/components/hospital/PrintMovDiarios'),
    },
    
    props:{
        dialogMovs:Boolean,
        area: Number,
        areaText:String
    },

    data:()=>({
        loading:false,

        estaFecha:'',

        menuFecha:false,
        pickerFecha:'',

        fechaLoad:'',

        movimientos:[],
        totales:{},
        dialogImprime:false,

        totalesProc:[],
    }),
    methods:{
        async cargaDia(){
            
            this.loading = true
            let params = {
                areaMovimiento:this.area,
                fechaMovimiento: this.fechaLoad
            }

            try{
                let oh = await this.$http({
                    method:'GET',
                    url:'/movimientos/movimientoDiario',
                    params:params,
                })
                this.loading = false
                this.movimientos=oh.data.root
                this.totales=oh.data.totales

            } catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cargaInternamiento: function(internamiento){
            
            if (this.fechaLoad){
                let fl1 = this.fechaLoad.split('/')
                this.$store.commit('security/SET_ESTA_FECHA',fl1[0]+'-'+fl1[1]+'-'+fl1[2])
                //console.log(this.fechaLoad)
                //console.log(fl1)
            }

            //console.log(internamiento)
            this.$router.push({path:`/hospital/interna/`+internamiento})
        },
        seleccionaFecha(dt){
            this.menuFecha=false
            let dtA = dt.split('-')
            this.estaFecha = new Date(dtA[0], dtA[1]-1, dtA[2])
        },

        diaSiguiente(){

            // La fecha destino
            let da = this.fechaLoad.split('/')
            da = new Date(da[2], da[1]-1, da[0])
            da = da.getTime()
            da = da + 86400000

            // La fecha actual
            let d1 = this.$store.getters['security/estaFechaMax']
            console.log(d1)
            d1 = d1.split('-')
            d1 = new Date(d1[0], d1[1]-1, d1[2])
            d1 = d1.getTime()

            // Compara 
            if (da > d1){
                // Rechaza
                this.$swal.fire({
                    icon: 'info',
                    title: 'No se puede avanzar',
                    text: 'No se pueden consultar fechas en el futuro',
                })
            } else {
                // Avanza
                this.fechaLoad = this.moment(da).format('DD/MM/YYYY')
                this.cargaDia()
            }

        },
        diaAnterior(){
            //console.log(this.fechaLoad)
            let da = this.fechaLoad.split('/')
            da = new Date(da[2], da[1]-1, da[0])
            da = da.getTime()
            da = da - 86400000
            this.fechaLoad = this.moment(da).format('DD/MM/YYYY')
            this.cargaDia()
        },
        async imprime(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/enfermeria/procTodosImprime',
                    params:{
                        areaMovimiento:this.area,
                        fechaMovimiento: this.moment(this.estaFechaDisplay).format("DD-MM-YYYY"),
                    },
                })
                this.totalesProc=req.data.root
                this.dialogImprime=true

            } catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }


        }
    },

    computed:{
        dialog:{
            get(){
                return this.dialogMovs
            },
            set(value){
                this.$emit('update:dialogMovs',value)
            },
        },
        estaFechaGral(){
            return this.$store.getters['security/estaFechaMax']
        },
        estaFechaDisplay(){
            let dt = this.fechaLoad.split('/')
            return new Date(dt[2], dt[1]-1, dt[0])
            
        }
        
    },

    watch:{
        dialogMovs(val){
            if (val){
                let dt = this.$store.getters['security/estaFechaMax'].split('-')
                this.fechaLoad = dt[2]+'/'+(dt[1])+'/'+dt[0]
                this.cargaDia()
            }
        },
        estaFecha(){
            let dt = this.pickerFecha.split('-')
            this.fechaLoad = dt[2]+'/'+(dt[1])+'/'+dt[0]
            this.cargaDia()
        },
    },

}
</script>

<style>

</style>