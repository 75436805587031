<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
    >
        <v-card class="fondo">
            <div>
                <v-card-text>
                    <v-img
                        class="white--text align-top"
                        max-height="800"
                        contain
                        :src="'/images/areas/'+this.$route.params.id+'.png'"
                        @click="cancela"
                    />
                </v-card-text>
            </div>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        area: Number,
        dialogModal: Boolean,
    },

    data:()=>({

    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{

    },
}
</script>

<style>

</style>