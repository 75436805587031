<template>
    <v-dialog
            v-model="dialog"
            max-width="600"
    >

        <v-card>
            <v-card-title
                class="headline purple lighten-2"
                primary-title
            >
                <span class="white--text">En espera</span>
            </v-card-title>

            <v-card-text>

                <!-- El loading -->
                <div v-if="cargandoEspera">
                    <v-skeleton-loader
                        type="list-item-two-line, list-item-two-line"
                    ></v-skeleton-loader>
                </div>

                <!-- El listado de pacientes en espera -->
                <div v-if="!cargandoEspera">
                    <v-list-item v-for="itm in enEspera" :key="itm.internamiento" >
                        <v-list-item-content>
                            <v-list-item-title>({{itm.cip}}) {{itm.nombreCompleto}}</v-list-item-title>
                            <v-list-item-subtitle>{{itm.motivo}}</v-list-item-subtitle>
                            <v-divider/>
                        </v-list-item-content>
                    
                        <v-list-item-icon>
                            <v-menu max-height="200">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="grey"
                                        icon
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-bed</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        v-for="(item, index) in camaLibre"
                                        :key="index"
                                        @click="asignaCama(itm, item)"
                                    >
                                        <v-list-item-title>{{ item.cama }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item-icon>
                    </v-list-item>
                </div>


            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        enEspera:Array,
        camaLibre:Array,
        dialogEspera:Boolean,
    },
    data:()=>({
        cargandoEspera:false,
    }),
    methods:{
        async asignaCama(a,b){

            this.cargandoEspera = true

            let params = {
                movimiento:a.movimiento,
                internamiento:a.internamiento,
                area:a.area,
                servicio:a.servicio,

                cama:b.cama,
            }
            
            try{
                let mv = await this.$http({
                    method:'POST',
                    url:'/movimientos/actualizacama',
                    params:params
                })

                this.cargandoEspera = false

                if (mv.data.estado == 'true'){
                    this.$emit('asignado')
                    this.dialog=false
                    
                    // Lo que se va a hacer si aplica el cambio de cama
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                this.cargandoEspera = false
                this.$store.dispatch('security/revisaError',err)
            }

        },
    },
    computed:{
         dialog:{
            get(){
                return this.dialogEspera
            },
            set(val){
                this.$emit('update:dialogEspera',val)
            },


        },
    }
}
</script>

<style>

</style>