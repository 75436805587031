<template>
    <div class="mx-3">

        <div v-if="loadingCarga">
            <v-skeleton-loader
                type="image, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            ></v-skeleton-loader>
        </div>

        <v-card v-if="!loadingCarga">
            <v-img
                class="white--text align-top"
                height="200px"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                :src="imagen"
            >
                <div
                    @click="dialogEspera=true"
                    v-if="totalEspera > 0 && $store.state.security.roles.includes('ROLE_ADMISION')"
                    class="float-right ma-6"
                >
                    <v-badge
                        color="red"
                        :content='totalEspera'
                    >
                        En espera
                    </v-badge>
                </div>
            </v-img>

            <v-toolbar dense elevation="1" dark :color="colorArea">
                <v-btn text icon to="/dashboard">
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                <v-toolbar-title>
                    Ocupación {{area}}
                </v-toolbar-title>
                <v-spacer/>

                <v-btn icon dark @click="cargaOcupacion">
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
                <!-- El botón de historial del internamiento -->
                <v-btn text icon @click="dialogMovs=true">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-history
                            </v-icon>
                        </template>
                        <span>Movimientos diarios</span>
                    </v-tooltip>
                </v-btn>

                <!-- El botón de agregar internamiento -->
                <v-btn
                    v-if="$store.state.security.roles.includes('ROLE_ADMISION')"
                    text
                    icon
                    @click="dialogNuevoInter=true"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-account-plus
                            </v-icon>
                        </template>
                        <span>Nuevo internamiento</span>
                    </v-tooltip>
                </v-btn>

            </v-toolbar>

            <v-card-text>
                <v-toolbar dense elevation="1" dark :color="colorArea">
                    <v-toolbar-title>Camas</v-toolbar-title>
                    <v-spacer/>
                    <v-btn
                        text
                        icon
                        @click="dialogPlano=true"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-map
                                </v-icon>
                            </template>
                            <span>Dist. física</span>
                        </v-tooltip>
                    </v-btn>
                </v-toolbar>


                <v-list dense rounded flat nav>
                    <v-list-item v-for="(cama, id) in camas" :key="id" class="my-1 grey lighten-3">

                        <v-list-item-icon class="pl-2 d-block">
                            <div class="ml-3" >
                                {{areaPrefijo}}-{{cama}}
                            </div>
                            <div class="text-caption ml-3" >
                                {{estePac(areaPrefijo+'-'+cama).ingreso}}
                            </div>
                            <div class="text-caption ml-3" >
                                {{estePac(areaPrefijo+'-'+cama).servicioNombre}}
                            </div>
                        </v-list-item-icon>
                        <v-list-item-content v-if="estePac(areaPrefijo+'-'+cama).internamiento">
                            <v-list-item-subtitle><b>{{estePac(areaPrefijo+'-'+cama).nombre}}</b></v-list-item-subtitle>
                            <v-list-item-subtitle v-if='estePac(areaPrefijo+"-"+cama).curp.endsWith("XX")'><span class="orange--text">{{estePac(areaPrefijo+'-'+cama).curp}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if='!estePac(areaPrefijo+"-"+cama).curp.endsWith("XX")'><span>{{estePac(areaPrefijo+'-'+cama).curp}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle>{{estePac(areaPrefijo+'-'+cama).motivo}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="estePac(areaPrefijo+'-'+cama).internamiento" class="d-inline">
                            <div class="d-flex justify-end">
                                <v-icon @click="cargaInternamiento(estePac(areaPrefijo+'-'+cama).internamiento)">
                                    mdi-magnify
                                </v-icon>
                                <v-icon v-if="$store.state.security.roles.includes('ROLE_ADMISION')" @click="abreCambiosDialog(int)">
                                    mdi-swap-horizontal
                                </v-icon>
                            </div>

                            <div>
                                {{estePac(areaPrefijo+'-'+cama).derechobabienciaNombre}}
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-toolbar dense elevation="1" dark color="blue" v-if="adicionales>0">
                    <v-toolbar-title>Adicionales</v-toolbar-title>
                </v-toolbar>

                <v-list dense rounded flat nav>
                    <v-list-item v-for="(cama, id) in adicionales" :key="id" class="my-1 grey lighten-3">

                        <v-list-item-icon class="pl-2 d-block">
                            <div class="ml-3">
                                {{areaPrefijo}}-AD-{{cama}}
                            </div>
                            <div class="text-caption ml-3" >
                                {{estePac(areaPrefijo+'-AD-'+cama).ingreso}}
                            </div>
                            <div class="text-caption ml-3" >
                                {{estePac(areaPrefijo+'-AD-'+cama).servicioNombre}}
                            </div>
                        </v-list-item-icon>
                        <v-list-item-content v-if="estePac(areaPrefijo+'-AD-'+cama).internamiento">
                            <v-list-item-subtitle><b>{{estePac(areaPrefijo+'-AD-'+cama).nombre}}</b></v-list-item-subtitle>
                            <v-list-item-subtitle v-if='estePac(areaPrefijo+"-AD-"+cama).curp.endsWith("XX")'><span class="orange--text">{{estePac(areaPrefijo+'-AD-'+cama).curp}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if='!estePac(areaPrefijo+"-AD-"+cama).curp.endsWith("XX")'><span>{{estePac(areaPrefijo+'-AD-'+cama).curp}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle>{{estePac(areaPrefijo+'-AD-'+cama).motivo}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="estePac(areaPrefijo+'-AD-'+cama).internamiento" class="d-inline">
                            <div class="d-flex justify-end">
                                <v-icon @click="cargaInternamiento(estePac(areaPrefijo+'-AD-'+cama).internamiento)">
                                    mdi-magnify
                                </v-icon>

                                <v-icon v-if="$store.state.security.roles.includes('ROLE_ADMISION')" @click="abreCambiosDialog(int)">
                                    mdi-swap-horizontal
                                </v-icon>
                            </div>
                            <div>
                                {{estePac(areaPrefijo+'-AD-'+cama).derechobabienciaNombre}}
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

            </v-card-text>
        </v-card>

        <mov-diarios
            :dialogMovs.sync="dialogMovs"
            :area="parseInt(this.$route.params.id)"
            :areaText="area"
        />

        <nuevo-inter
            :dialogNuevoInter.sync="dialogNuevoInter"
            :area="parseInt(this.$route.params.id)"
            @agregado="cargaOcupacion"
        />

        <espera-area
            :enEspera="pacientesEspera"
            :camaLibre="camaLibre"
            :dialogEspera.sync="dialogEspera"
            @asignado="cargaOcupacion"
        />

        <cambios-interna
            :dialogCambio.sync="dialogCambio"
            :internamiento='esteInterna'
            :camaLibre="camaLibre"
            @asignado="cargaOcupacion"
        />
        <plano-cama
            :dialogModal.sync="dialogPlano"
            :area="parseInt($route.params.id)"
        />
    </div>
</template>

<script>
import MovDiarios from '@/components/hospital/MovDiarios'
import NuevoInter from '@/views/hospital/nuevoInternamiento'
import EsperaArea from '@/views/hospital/esperaArea'
import CambiosInterna from '@/views/hospital/cambiosInterna'
import PlanoCama from '@/components/hospital/PlanoCama'
export default {


    mounted(){
        this.cargaOcupacion()
    },

    components:{
        MovDiarios,
        NuevoInter,
        EsperaArea,
        CambiosInterna,
        PlanoCama,
    },

    data: ()=>({
        internados:[],
        area:'',
        areaPrefijo:'',
        imagen:'',
        loadingCarga:true,

        // Para los pacientes en espera
        totalEspera:0,
        dialogEspera:false,
        pacientesEspera:[],
        camaLibre:[],

        // Para los movimientos del paciente
        dialogMovs:false,
        dialogNuevoInter:false,

        // Para los movimientos del internamiento
        esteInterna:{},
        dialogCambio:false,

        camas:0,
        adicionales:0,

        dialogPlano:false,
    }),
    methods:{
        estePac(cama){
            return (this.internados.filter(pc=>pc.cama == cama))[0]
        },
        cargaInternamiento: function(internamiento){
            //console.log('Carga el internamiento '+internamiento)
            this.$store.commit('security/SET_ESTA_FECHA','')
            this.$router.push({path:`/hospital/interna/`+internamiento})
        },
        abreCambiosDialog(int){
            //console.log(int)
            this.esteInterna = int
            this.dialogCambio = true
        },
        async cargaOcupacion(){

            this.imagen = '/images/imgarea/Area'+this.$route.params.id+'.jpg'
            this.loadingCarga = true
            try{
                let dc = await this.$http({
                    method:'GET',
                    url:'/movimientos/encamados2',
                    params:{area:this.$route.params.id}
                })
                this.loadingCarga = false

                this.internados= dc.data.root
                this.area = dc.data.nombreArea
                this.totalEspera=dc.data.totalEspera
                this.pacientesEspera=dc.data.espera
                this.camaLibre = dc.data.camaLibre

                this.camas = dc.data.totalCamas
                this.adicionales = dc.data.adicionales
                this.areaPrefijo = dc.data.areaPrefijo


            }catch(err){
                this.loadingCarga = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    computed:{
        colorArea(){
            let color = ''
            if ([5, 6, 7, 8, 13, 14].indexOf(parseInt(this.$route.params.id))>= 0) {
                color='primary'
            } else {
                color='secondary'
            }
            return color;
        },
        camasLibres(){
            return(this.camaLibre.map(x=>x.cama))
        }
    },

    
}
</script>

<style>

</style>