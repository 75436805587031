<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
    >
        <v-form
            ref="buscaForm"
        >
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                >
                    Seleccionar paciente
                </v-card-title>


                <v-card-text>

                    <v-row class="px-5" no-gutters> 
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="datosBusca.nombres"
                                label="Nombres"
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="datosBusca.apellidoPaterno"
                                label="Primer apellido"
                                dense
                                clearable
                            ></v-text-field>

                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="datosBusca.apellidoMaterno"
                                label="Segundo apellido"
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="datosBusca.curp"
                                label="C.U.R.P."
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-menu
                                v-model="menuFecha"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="datosBusca.fechaNacimiento"
                                        label="Fecha de nacimiento"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="datePicker"
                                    @input="menuFecha = false"

                                    no-title
                                    scrollable
                                    locale="es-mx"
                                    show-current
                                />
                            </v-menu>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="datosBusca.id"
                                label="C.I.P."
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-data-table
                        dense
                        item-key="id"
                        :loading="loadingPac"
                        loading-text="Cargando... espere"
                        :headers="headers"
                        :items="datosPacientes"
                        :items-per-page="limit"

                        disable-filtering
                        disable-pagination
                        hide-default-footer
                        disable-sort
                    >
                        <!-- ---------------------------
                        Slot de NO HAY DATOS
                        ------------------------------- -->
                        <template v-slot:no-data>
                            <span>No se encontraron resultados...</span>
                        </template>

                        <template v-slot:[`item.id`]="{ item }">
                            <b>{{item.id}}</b><br/>
                            <div v-if="item.otrosFechaDefuncion">
                                <v-icon color="red" small>mdi-skull</v-icon> <span class="red--text"><b>{{item.otrosFechaDefuncion}}</b></span>
                            </div>

                            <div v-if="item.internado == 1">
                                <v-icon color="green" small>mdi-bed</v-icon> <span class="green--text"><b>{{item.internadoDatos.cama}}</b></span>
                            </div>
                        </template>

                        <template v-slot:[`item.nombreCompleto`]="{ item }">
                            <b>{{item.nombreCompleto}}</b><br/>
                            <b>CURP:</b> {{item.curp}}
                        </template>

                        <template v-slot:[`item.edad`]="{ item }">
                            {{item.edad}} ({{item.fechaNacimiento}})
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                v-if="(item.internado==0 && !item.otrosFechaDefuncion||todos == true)"
                                icon
                                @click="entregaPaciente(item.id)"
                            >
                                <v-icon color="primary">mdi-check-bold</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                    <!-- El paging -->
                    <div v-if="!loadingPac">
                        <v-pagination
                            circle
                            v-model="page"
                            :length="pageLength"
                            total-visible="7"
                            @input="busca"
                        ></v-pagination>

                        <div class="text-center caption">
                            <b>{{pageDe}}</b> a <b>{{pageA}}</b> de <b>{{total}}</b>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        @click="buscaBtn"
                    >
                        Buscar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogBuscaPaciente:Boolean,
        todos:Boolean
    },

    data:()=>({
        datosBusca:{
            nombres: null,
            apellidoPaterno: null,
            apellidoMaterno: null,
            curp: null,
            folioDerechohabiencia: null,
            id: null,
            fechaNacimiento: null,
            estatusExpediente: null,
        },

        headers: [
            {
                text: 'C.I.P.',
                value: 'id',
                sortable:false,
                align:'center',
                width:  "20%",
            },

            {
                text: 'Nombre',
                value: 'nombreCompleto',
                sortable:false,
                width:  "55%",
            },
            {
                text: 'Sexo',
                value: 'sexo',
                sortable:false,
                align:'center',
                width:  "5%",
            },
            {
                text: 'Edad',
                value: 'edad',
                sortable:false,
                width:  "10%",
                align:'center',
            },
            {
                text: '',
                value: 'actions',
                sortable:false,
                width:  "10%",
                align:'center',
            },
        ],

        datosPacientes:[],
        // Para la paginación
        total:0,
        page: 1,
        start: 0,
        limit: 10,

        loadingPac:false,

        // Para el picker de fecha
        menuFecha:false,
        datePicker:'',
    }),

    methods:{
        async busca(){

            this.loadingPac=true
            let params = {...this.datosBusca}

            params.page = this.page
            params.start = (this.page -1) * this.limit
            params.limit = this.limit
            try{
                let pac = await this.$http({
                    methos:'GET',
                    url:'/pacientes/list',
                    params:params
                })
                this.loadingPac=false
                this.total = pac.data.total
                this.datosPacientes=pac.data.root
            }catch(err){
                this.loadingPac = false
                this.$store.dispatch('security/revisaError',err)
            }

        },
        buscaBtn(){
            this.page = 1
            this.busca()
        },
        entregaPaciente(id){
            this.$emit('update:paciente',id)
            this.dialog = false
        },
    },
    computed:{
        /* -----------------
        Computados para la paginación
        -------------------- */
        pageLength: function(){
            return (Math.ceil(this.total / this.limit));
        },
        pageDe: function(){
            let pd = 0
            if (this.total > 0) {
                pd = ((this.page - 1) * this.limit)+1
            }
            return pd
        },
        pageA: function(){
            let pa = this.page * this.limit
            if (pa > this.total ){
                pa = this.total
            }
            return (pa)
        },
        /* -------------------------- */
        dialog:{
            get(){
                return this.dialogBuscaPaciente
            },
            set(val){
                this.$refs.buscaForm.reset()
                this.datosPacientes=[]
                this.total = 0
                this.$emit('update:dialogBuscaPaciente',val)
            },
        },
    },
    watch:{
        datePicker(val){
            if (val){
                let f1 = val.split('-')
                //console.log(f1)
                this.datosBusca.fechaNacimiento = f1[2]+'/'+f1[1]+'/'+f1[0] 
            }
        },
    },
}
</script>

<style>

</style>