<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        persistent
    >
        <v-form
            @submit="guarda"
            onSubmit="return false;"
            ref="inciForm"
            v-model="validInter"
        >
            <v-card>
                <v-card-title
                    class="headline green lighten-2"
                    primary-title
                >
                    <span class="white--text">Nuevo internamiento</span>
                </v-card-title>

                <v-card-text class="pt-5">
                    <v-expand-transition>
                        <v-alert text border="left" type="error" v-if="alertError">
                            {{errorMsg}}
                        </v-alert>
                    </v-expand-transition>

                    <v-expand-transition>
                        <v-alert text border="left" type="success" v-if="alertPaciente">
                            {{pacienteMsg}}
                        </v-alert>
                    </v-expand-transition>
                    <v-row no-gutters> 
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <!-- ----------------Revisar-------------------- -->
                            <v-text-field
                                append-icon="mdi-magnify"
                                @click:append="buscarPaciente"
                                v-model="int.cip"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                    value => value >= 10000010 || 'Este número no es válido',
                                ]"
                                label="Paciente"
                                type="number"
                                filled
                                rounded
                                dense
                                
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-select
                                v-model="int.procedencia"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"
                                :items="procedenciaItems"
                                label="Procedencia"
                                filled
                                rounded
                                dense
                            ></v-select>
                        </v-col>

                        
                        <v-col class="px-1" cols="12">
                            <v-expand-transition>
                                
                                <v-autocomplete
                                    v-if="int.procedencia == 3"
                                    v-model="int.origen"
                                    :items="listaUnidades"
                                    :loading="loadingUnidades"
                                    label="Unidiad que refiere"
                                    :search-input.sync="queryUnidad"
                                    item-text="clues"
                                    item-value="id"
                                    :rules="[
                                        value => !!value || 'Requerido.',
                                    ]"
                                    append-icon=''
                                    no-filter
                                    filled
                                    rounded
                                    dense
                                >
                                    <!-- El template para la lista de los datos -->
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title>({{data.item.clues}}) {{data.item.nombre}} {{data.item.municipio}}</v-list-item-title>
                                            <v-list-item-subtitle>UNIDAD {{data.item.tipoInstitucion}} {{data.item.dependencia}} {{data.item.estado}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                    
                                    <!-- El template para el control de paginate -->
                                    <template v-slot:append-item>
                                        <v-pagination
                                            v-model="pageUnidad"
                                            circle
                                            :length="pageLengthUnidad"
                                            total-visible="7"
                                            @input="cargaUnidades"
                                        ></v-pagination>
                                    </template>
                                </v-autocomplete>
                            </v-expand-transition>
                        </v-col>

                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-text-field
                                v-model="int.medico"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"
                                label="Médico"
                                filled
                                rounded
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-select
                                v-model="int.servicio"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"
                                :items="$store.state.catalogos.servicios"
                                item-text="servicio"
                                item-value="id"
                                @focus="$store.dispatch('catalogos/cargaServicio')"
                                label="Servicio"
                                filled
                                rounded
                                dense
                            ></v-select>
                        </v-col>

                        <v-col class="px-1" cols="12">
                            <v-text-field
                                v-model="int.motivo"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                ]"
                                label="Motivo"
                                filled
                                rounded
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col class="px-1" cols="12">
                            <v-textarea
                                v-model="int.observaciones"
                                label="Observaciones"
                                filled
                                rounded
                                dense
                            ></v-textarea>

                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    
                    <v-btn
                        text
                        color="error"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>
                    
                    <v-btn
                        :loading="guardandoInter"
                        color="success"
                        :disabled="!internamientoValido"
                        type='submit'
                    >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <busca-paciente
            :dialogBuscaPaciente.sync="dialogBuscaPaciente"
            :paciente.sync="int.cip"
        />
    </v-dialog>
</template>

<script>
import BuscaPaciente from '@/components/hospital/buscaPaciente'
export default {
    components:{
        BuscaPaciente,
    },
    props:{
        dialogNuevoInter:Boolean,
        area:Number,
    },
    data:()=>({
        validInter:false,
        guardandoInter:false,

        alertPaciente:false,
        pacienteMsg:'',

        alertError:false,
        errorMsg:'',

        pacienteValido:false,

        // Para la búsqueda de paciente
        dialogBuscaPaciente:false,
        datosPaciente:{},

        int:{
            cip:null,
            origen:null, //del catalogo de CLUES
            procedencia:null, // urgencias, consulta o referido
            medico:null,
            servicio:null, // Del catalogo de servicios (al que pertnece)
            //area:null, // El area actual
            motivo:null,
            observaciones:null,
            tipoInternamiento:1, // Es 1 para internamientos
            versionInternamiento:2, // Version 2 para esta versión del sistema
            ministerioPublico:0, // A futuro, debe ser 0
        },

        procedenciaItems:[
            {value:1, text:'Consulta externa'},
            {value:2, text:'Urgencias'},
            {value:3, text:'Referido'},
        ],
        
        servicioItems:[],

        // Para el filtro de unidades
        loadingUnidades:false,
        queryUnidad:'',
        totalUnidad:0,
        limitUnidad:10,
        listaUnidades:[],

        // Page de unidades
        pageUnidad:1,
    }),
    methods:{

        // Genera el nuevo internamiento (guarda)
        async guarda(){
            this.guardandoInter = true
            let params = {...this.int}

            params.area = this.area

            try{
                let ni = await this.$http({
                    method:'POST',
                    url:'/movimientos/createV3',
                    data:params
                })

                this.guardandoInter = false

                if (ni.data.estado == true){
                    this.dialog=false
                    this.$emit('agregado')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.guardandoInter = false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        // Cierra el dialog (cancela)
        cierra(){
            this.dialog=false
        },


        // Carga unidades para el AUTOCOMPLETE
        async cargaUnidades(){
            
            if (!this.loadingUnidades){
                if (this.queryUnidad && this.queryUnidad.length >= 3){

                    this.loadingUnidades = true
                    let params = {
                        query: this.queryUnidad,
                        page: this.pageUnidad,
                        start: (this.pageUnidad -1) * this.limitUnidad,
                        limit: this.limitUnidad,
                    }
        
                    try {
                        var resp = await this.$http
                        .get('/catalogos/hospitales',{
                            params:params,
                        })
                        this.listaUnidades = resp.data.root
                        this.totalUnidad = resp.data.total
                        this.loadingUnidades = false
                        
                    } catch(err) {
                        console.log(err)
                        this.loadingUnidades = false
                        this.$store.dispatch('security/revisaError',err)
                    }
                }
            }
        },

        // abre diálogo para buscar paciente
        buscarPaciente(){
            this.int.cip = null
            this.dialogBuscaPaciente=true
        },
        async revisaPaciente(){
            // si total es 1, pasar, si es 0, mostrar error
            //Hace una petición a pacientes/list y obtener el registro 0
            // De ahi nececitamos en root:
            // internado, debe ser 0
            // internamientoDatos es un objeto que tiene los datos del internamiento (areaNom y cama)
            // tipoRegistro, debe ser 2. Si es 1, notificar para hacer cambio, si es 3, es baja y debe de mostrar error
            // otrosFechaDefuncion, debe ser blanco o null
            this.pacienteValido=false
            try{
                let dp = await this.$http({
                    url:'/pacientes/list',
                    method:'GET',
                    params:{id:this.int.cip},
                })
                // Si existe el paciente
                if (dp.data.total == 1){
                    let datos = dp.data.root[0]

                    // Buscar si tiene defuncion
                    if (datos.otrosFechaDefuncion){
                        this.alertPaciente=false
                        this.alertError = true
                        this.errorMsg= 'El paciente '+this.int.cip+' no se puede internar (defunción)'
                    }

                    // Buscar si tiene internamientos
                    if (datos.internado){
                        this.alertPaciente=false
                        this.alertError = true
                        this.errorMsg= 'El paciente '+this.int.cip+' tiene internamientos activos ('+datos.internadoDatos.cama+')'
                    }

                    // Si no tiene nada, muestra los datos del paciente
                    if (datos.otrosFechaDefuncion=='' && datos.internado == 0){
                        this.alertPaciente=true
                        this.alertError=false
                        this.pacienteMsg=datos.nombreCompleto+' ('+datos.fechaNacimiento+')'
                        this.pacienteValido = true
                    }
                    
                } else {
                    this.alertPaciente=false
                    this.alertError = true
                    this.errorMsg= 'El paciente '+this.int.cip+' no existe'
                    
                }
                
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },

    computed:{
        // Para el manejo del DIALOG
        dialog:{
            get(){
                return this.dialogNuevoInter
            },
            set(val){
                this.$refs.inciForm.reset()
                this.alertPaciente=false
                this.alertError=false

                this.pacienteMsg=''
                this.errorMsg=''


                this.$emit('update:dialogNuevoInter',val)
            },
        },

        // Para el PAGING del autocomplete
        pageLengthUnidad(){
            return (Math.ceil(this.totalUnidad / this.limitUnidad));
        },
        // Evalua si el formualrio esta lleno y si el paciente es válido
        internamientoValido(){
            return (this.validInter && this.pacienteValido)
        }
        
    },
    watch:{
        // Cambio en el número de expediente, revisa
        'int.cip'(val){
            if (val && val >= 10000010){
                this.revisaPaciente()
            }
        },
        // Para el query del autocomplete
        queryUnidad(){
            this.pageUnidad = 1
            this.cargaUnidades()
        },
        
    },

}
</script>

<style>

</style>